import React from 'react';
import MapImage from "../assets/map.png"
const MapComponent = () => {
    const position = [37.7749, -122.4194]; // Default location (San Francisco)

    return (
        <div>
            <img src={MapImage} style={{ height: "100%", width:"90%" }} />
        </div>
    );
};

export default MapComponent;
