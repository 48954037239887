import React, { useEffect, useState } from "react";
import { Grid, Typography, Rating } from "@mui/material";
import auto1 from "../assets/vehicleimage/1auto.png"
import auto2 from "../assets/vehicleimage/2auto.png"
import auto3 from "../assets/vehicleimage/3auto.png"
import dipankar from "../assets/vehicleimage/dipankar.png"
import roy from "../assets/vehicleimage/roy.jpg"
import employee from "../assets/vehicleimage/employee.jpeg"
import machine from "../assets/vehicleimage/machine.png"
import working from "../assets/vehicleimage/working.jpeg"
import TestimonialSlider from "../CommonComponents/TestimonialSlider";
import Gallery from "./Gallery";
import MapComponent from "../CommonComponents/Map";
import firestore from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { NavLink } from 'react-router-dom';

const rigidBuild = [
  { description: "Electric Vehicles (EVs) are revolutionizing the automotive industry, offering a cleaner and more sustainable mode of transportation. Unlike traditional vehicles powered by internal combustion engines, EVs rely on electricity stored in batteries to propel them forward.", image: auto2 },
  {
    description: "One of the primary advantages of EVs is their environmental friendliness. By eliminating tailpipe emissions, electric vehicles contribute to reducing air pollution and greenhouse gas emissions, helping combat climate change.", image: auto3
  }]
const owners = [
  { name: "Dipankar Mukherjee", description: "The owner and founder of CARRITO with 2 decades of experience in Manufacturing sector of India. Is an expertise and visionary in the EV market and helped build EV companies from ground up. Entrepreneur with MBA and Psychology background, led pioneering E-rickshaw manufacturing projects in India. Visionary in Indian Electric Vehicles sector, achieved a 90 crore INR turnover as a first-generation entrepreneur.", image: dipankar },
  {
    name: "Debdut Roy", description: "Director at CARRITO, brings 20+ years of diverse business experience across continents. With a background in Mechanical Engineering and an MBA, he initiated his entrepreneurial journey as a teenager, managing food and metal factories. Roy has led numerous international projects spanning Europe, Africa, Asia, and the GCC.", image: roy
  }];

export default function Home() {
  const [testimonialData, setTestimonialData] = useState([])
  useEffect(() => {
    fetchData()
  }, []);
  const fetchData = async () => {
    try {
      const collectionRef = collection(firestore, 'testimonials');
      const snapshot = await getDocs(collectionRef);
      const newData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setTestimonialData(newData);
    } catch (error) {
      console.error('Error fetching data from Firestore:', error.message);
    }
  };
  return (
    <div id="dashboard" className="dashboard-wrapper" >
      <div className="dashboard-content">
        <div className="pd-64 align-wide pd-b-10">
          <Grid item paddingBottom={3}>
            <Typography variant="h1" className="join-us">
              Join us
            </Typography>
            <Typography variant="h1" className="join-us">
              on this transformative ride.
            </Typography>
          </Grid>
          <Grid item lg={6} sm={12} md={6} xl={10} xs={12} paddingBottom={3}>
            <img src={auto1} style={{ width: "100%" }} />
          </Grid>
          <Grid lg={12} sm={12} md={12} xl={12} container paddingTop={5} paddingBottom={5} >
            <Grid lg={12} xl={12} md={12} container >
              <Grid item lg={6} sm={12} md={8} xl={8} marginRight={3}>
                <div style={{fontSize: "17", fontWeight: "600"}}  lineHeight={2} className="color-green">At Carrito, we pride ourselves on developing cutting-edge electric vehicles that not only reduce carbon footprints but also provide cost-effective and efficient solutions for last-mile connectivity and cargo transportation. Our commitment to excellence extends beyond our products, encompassing a dedication to environmental responsibility, customer satisfaction, and community development.
                </div>
              </Grid>
              <Grid item lg={4} sm={12} md={8} xl={7} >
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <NavLink to="/contact">
              <button className="btn-primary">TALK TO US</button>
            </NavLink>
          </Grid>

          <Grid container flexDirection={"column"}>
            <Typography variant="h1" className="rigid-build" paddingTop={5}> A Rigid Build
            </Typography>
          </Grid>
        </div>
        <Grid className=" align-wide pd-l-r-64" lg={12} sm={12} md={12} xl={12} container spacing={2} flexDirection={"row"} display={"flex"} paddingLeft={5} >
          {rigidBuild.map((value) => (
            <Grid lg={6} sm={12} md={12} xl={6} xs={12} key={value} item flexDirection={"row"} display={"flex"}>
              <Grid marginRight={2}>
                <img src={value.image} style={{ width: "100%" }} />
                <p variant="h5" className="color-green mr-t-24 font-16">{value.description}
                </p>
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid className="align-wide pd-l-r-64" lg={12} sm={12} md={12} xl={12} container spacing={2} padding={5}>
          <Grid lg={6} item display={"flex"}>
            <Grid>
              <Typography variant="h3" className="color-green mr-t-24">{"What makes us the best in business"}
              </Typography>
            </Grid>
          </Grid>
          <Grid lg={6} item display={"flex"}>
            <Grid marginRight={2}>
              <p variant="h5" className="color-green mr-t-24 font-16">{"Welcome to Carrito, where innovation meets sustainability. We are a pioneering E-rickshaw and E-loader company committed to revolutionizing urban mobility and logistics solutions in India and beyond. With a vision to create eco-friendly alternatives, we embark on a journey to redefine the future of transportation."}
              </p>
            </Grid>
          </Grid>
        </Grid>


        <Grid className="align-wide pd-l-r-64" lg={12} sm={12} md={12} xl={12} container paddingBottom={5} >
          {owners.map((value) => (
            <Grid lg={6} xl={6} md={12} xs={12} key={value} container spacing={2} marginTop={2} marginRight={2}>
              <Grid item lg={6} sm={6} md={6} xs={12} xl={4} >
                <img src={value.image} style={{ width: "100%" }} />
              </Grid>
              <Grid item lg={6} sm={6} md={6} xl={8} xs={12} >
                <Typography variant="h3" className="color-green">{value.name}
                </Typography>
                <p className="color-green mr-t-24 font-16">{value.description}
                </p>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid lg={12} xl={12} className="ecosystem full-width">
          <Grid className="align-wide">
            <Typography variant="h6" color={"black"} marginBottom={2} fontSize={24}>
              ECOSYSTEM
            </Typography>
            <Typography variant="h2" color={"black"} className="positive-growth">
              Positive growth.
            </Typography>
          </Grid>

          <Grid lg={12} xl={12} sm={12} md={12} container marginTop={5}>
            <Grid lg={12} xl={12} sm={12} md={12} container marginTop={4} justifyContent={"center"}>
              <Grid item xl={4} lg={4} md={12} sm={12} marginRight={1} marginBottom={2}>
                <Typography variant="h6" fontWeight={600} fontSize={17}>Our vision at Carrito is to be a global leader in electric transportation, continuously pushing the boundaries of innovation. We envision a world where electric rickshaws, loaders, ambulances, and more seamlessly integrate into diverse environments, contributing to cleaner air, reduced carbon emissions, and improved accessibility for all.</Typography>
                <Typography variant="h6" fontWeight={600} marginTop={2} fontSize={17}>At Carrito, our mission is to redefine urban and rural transportation landscapes through innovation and sustainability. Committed to creating a greener future, we aim to provide efficient, affordable, and eco-friendly electric mobility solutions that enhance the lives of communities around the world.
                </Typography>
              </Grid>
              <Grid item lg={3} sm={6} md={6} xl={4} marginTop={4} marginRight={2}>
                <img src={employee} style={{ width: "100%" }} />
              </Grid>
              <Grid item lg={4} sm={6} md={6} xl={3.5} marginTop={4}>
                <img src={machine} style={{ width: "100%" }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid lg={12} xl={12} sm={12} md={12} container marginTop={4}  justifyContent={"center"}>
            <Grid item lg={7} sm={6} xs={12} md={6} xl={8} marginBottom={2} marginRight={2}>
              <img src={working} style={{ width: "100%" }} />
            </Grid>
            <Grid item lg={4} sm={12} xs={12} md={12} xl={3} marginRight={1} marginBottom={2} display={"flex"} style={{ justifyContent: "center", alignItems: "center" }}>
              <Typography variant="h6" fontWeight={600} fontSize={17} paddingTop={4}>Carrito strives to lead in electric mobility by serving as an innovation hub, continuously developing new electric vehicle concepts for urban and rural needs. With a focus on global expansion, the company aims to offer sustainable electric mobility solutions worldwide. Carrito is committed to building a comprehensive e-mobility ecosystem, covering diverse societal needs from e-rickshaws to innovative solutions like e-ambulances. Prioritizing positive community impact, environmental sustainability, and safety, the company actively engages in social responsibility initiatives, promotes clean energy sources, and ensures its vehicles provide safe and accessible transportation options for everyone.</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="align-wide pd-l-r-64" lg={12} sm={12} md={12} xl={12} container paddingTop={10} justifyContent={"center"} flexDirection={"column"}>
          <Typography variant="h5" textAlign={"center"} className="color-green">
            Testimonials
          </Typography>
          <Typography variant="h3" textAlign={"center"} fontWeight={600} className="color-green" marginTop={1}>
            Customers love our products
          </Typography>
        </Grid>
        <Grid className="align-wide pd-l-r-64" lg={12} sm={12} md={12} xl={12} container paddingTop={10}>
          <TestimonialSlider data={testimonialData} />
        </Grid>
        <Grid className="align-wide" marginTop={2}>
          <Gallery />
        </Grid>

        <Grid className="align-wide " lg={12} sm={12} md={12} xl={12} container spacing={2} paddingTop={5} paddingLeft={7} paddingBottom={5} >
          <Grid className="align-wide" lg={12} sm={12} md={12} xl={12} container flexDirection={"column"} paddingTop={5}>
            <Typography variant="h2" className="color-green">
              Where to find us
            </Typography>
            <Typography variant="h6" fontSize={18} marginTop={2} marginBottom={2} className="color-green">
              We earned a reputation for designing beautiful and innovative buildings that delight and inspire. We strive to deliver innovative solutions that have a minimal impact on the environment.
            </Typography>
          </Grid>

          <Grid lg={3} xl={3} md={12} container marginTop={2} marginRight={4} flexDirection={"column"} >
            <Typography style={{fontSize: "18", fontWeight: "600"}}>Address:{'\n'}</Typography>
            <Typography style={{fontSize: "16", fontWeight: "500"}}>Chhutipur, Singur,</Typography>
            <Typography style={{fontSize: "16", fontWeight: "500"}}>Near: Sanyasi Ghata Mandir</Typography>
            <Typography style={{fontSize: "16", fontWeight: "500"}}>District: Hooghly.Pin code:712124. West Bengal.India.</Typography>
            <Typography style={{fontSize: "18", fontWeight: "600"}} marginTop={2}>Telephone:</Typography>
            <Typography style={{fontSize: "16", fontWeight: "500"}} marginTop={1}>+91 98308 13600</Typography>
            <Typography style={{fontSize: "16", fontWeight: "500"}}>+380 668001075</Typography>
            <Typography style={{fontSize: "18", fontWeight: "600"}} marginTop={1}>Email:</Typography>
            <Typography style={{fontSize: "16", fontWeight: "500" }} marginTop={1}> <a className="color-green"> marketing@carritoev.com </a></Typography>
          </Grid>
          <Grid lg={8} xl={8} md={12} container marginTop={2}>
            <MapComponent />
          </Grid>
        </Grid>
        <Grid className="align-wide" lg={12} sm={12} md={12} xl={12} container display={"flex"}
          justifyContent={"center"} flexDirection={"column"} alignItems={"center"} spacing={2} paddingTop={5} paddingLeft={5} paddingBottom={5} >
          <Typography variant="h3" fontStyle={"italic"} className="color-green">
            Carrito
          </Typography>
          <Grid display={"flex"} marginTop={2}>
            <Grid item marginRight={2}>
              <i className="fa fa-facebook" style={{ fontSize: 24 }}></i>
            </Grid>
            <Grid item marginRight={2}>
              <i className="fa fa-twitter" style={{ fontSize: 24 }}></i>
            </Grid>
            <Grid item  >
              <i className="fa fa-youtube" style={{ fontSize: 24 }}></i>
            </Grid>
          </Grid>
        </Grid>
      </div >
    </div >
  );
};
