// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
const firebaseConfig = {
    apiKey: "AIzaSyCJqj0yAlPAvhNrzF9koyQRw5GA3BRpWmg",
    authDomain: "carritoev-2024.firebaseapp.com",
    projectId: "carritoev-2024",
    storageBucket: "carritoev-2024.appspot.com",
    messagingSenderId: "925575865709",
    appId: "1:925575865709:web:d79b68a003cff1c6bba664",
};

const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore(firebaseApp);

export default firestore;   