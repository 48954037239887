import React from "react";
import { Grid, Typography } from "@mui/material";
import gallery1 from "../assets/gallery/gallery1.png"
import gallery2 from "../assets/gallery/gallery2.png"
import gallery3 from "../assets/gallery/gallery3.jpg"
import gallery4 from "../assets/gallery/gallery4.jpeg"
export default function Gallery(props) {
  return (

    <Grid className="pd-l-r-64" lg={12} sm={12} md={12} xl={12} container paddingTop={5} paddingLeft={5} paddingBottom={5} >

      <Grid rid lg={12} xl={12} md={12} container spacing={2}>
        <Grid item lg={2} sm={12} md={2} xl={3} marginRight={3}>
        </Grid>
        <Grid item lg={6} sm={12} md={5} xl={4} xs={12} marginRight={3}>
          <Typography variant="h3" className="color-green" fontStyle={"italic"}>
            We have three models currently..
          </Typography>
          <Typography className="color-green" fontSize={14} style={{ width: "70%", marginTop: 10 ,fontSize: "16", fontWeight: "500" }}>
            The specific relevant content for this request, if necessary, delimited with characters: A perfect torque machine for hauling your load and accommodating your passengers which has impeccable range and build quality
          </Typography>
          <img src={gallery1} style={{ marginTop: 20, width: "100%" }} />
        </Grid>
        <Grid item lg={3} sm={12} md={4} xl={3} xs={12} padding={1} alignItems={"flex-end"} display={"flex"} >
          <img src={gallery2} alt="gallery 2" style={{ width: "100%" }} />
        </Grid>
      </Grid>
      <Grid lg={12} xl={12} md={12} xs={12} container spacing={2} marginTop={2}>
        <Grid item lg={5} sm={12} md={4} xl={4} xs={12} marginRight={2}>
          <img src={gallery3} style={{ marginTop: 20, width: "100%" }} />
        </Grid>
        <Grid item lg={4} sm={12} md={4} xl={4} xs={12} marginRight={2}>
          <img src={gallery4} style={{ marginTop: 20, width: "100%" }} />
        </Grid>
        <Grid item lg={2} sm={12} md={3} xl={2} display={"flex"} alignItems={"center"} >
          <Typography style={{fontSize: "30", fontWeight: "600" }} className="color-green">
            WHAT’S YOUR FAVOURITE COLOUR?
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
