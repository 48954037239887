import React from 'react';
import Slider from 'react-slick';
import { Paper, Typography, Rating, Slide, Highlight, Grid, Card, Avatar } from '@mui/material';
import { Carousel } from '@trendyol-js/react-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'



const testimonials = [
    {
        id: 1,
        rating: 4,
        description: "The driving experience is unparalleled – the instant torque and acceleration of the make every journey a thrilling adventure. Navigating city streets or cruising on the highway is a breeze, and the quiet hum of the electric motor adds a sense of tranquility to the drive.",
        image: 'https://example.com/image1.jpg',
        name: "Bishal Choudary",
        firstLetter: "B",
        company: "Sanjev Transports"
    },
    {
        id: 2,
        rating: 5,
        description: "Getting this electric vehicle has completely transformed my driving experience. The smooth power and quick acceleration make every trip a pleasure. Navigating city traffic or cruising on the open road is a breeze, and the silent electric motor adds a peaceful element to the entire journey.",
        image: 'https://example.com/image1.jpg',
        name: "Deepak Lal",
        firstLetter: "D",
        company: "Virtual logistics"
    },
];


const TestimonialSlider = (props) => {

    return (
        <Grid lg={12} xl={12} sm={12} container display={"flex"} justifyContent={"center"} paddingBottom={3} paddingTop={3} >
            {props.data?.map((item) => (
                <Grid xl={4} lg={5.5} md={5} sm={12} xs={12} marginTop={2} marginRight={2}>
                    <Card style={{ backgroundColor: "transparent", padding: 15, height: 300 }}>
                        <Rating defaultValue={item.rating} value={item.rating} size="small" disabled style={{ opacity: 1 }} sx={{
                            '& .MuiRating-iconFilled': {
                                color: '#62ff88',
                            },
                        }} />
                        <Typography variant='h6' className='color-green' style={{ height: 150, marginTop: 10, fontSize: 15 }}>
                            {item.description}
                        </Typography>
                        <Grid display={"flex"} marginTop={2}>
                            <Grid marginRight={2} marginTop={1}>
                                <Avatar sx={{ bgcolor: "orange" }}>{item.firstLetter}</Avatar>
                            </Grid>
                            <Grid marginTop={1}>
                                <Typography variant='h6' className='color-green' style={{ fontSize: 15 }}> {item.name}</Typography>
                                <Typography variant='h6' className='color-green' style={{ fontSize: 15 }}> {item.company}</Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default TestimonialSlider;
