import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Grid, Alert } from '@mui/material';
import db from '../firebase';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import firestore from '../firebase';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      // Reference to the collection
      const collectionRef = collection(firestore, 'queries');

      // Add a new document to the collection
      const docRef = await addDoc(collectionRef, {
        name: name,
        email: email,
        query: message,
        time: new Date()
      });

      console.log('Document written with ID: ', docRef.id);
      setEmail('')
      setName('')
      setMessage('')
    } catch (error) {
      console.error('Error adding document:', error.message);
    }
  };

  return (
    <div className='contact-content'>
      <Container>
        <Grid lg={12} xl={12} md={12} container>
          <Grid lg={5} xl={5} md={5} sm={12} xs={12} item marginTop={2} marginRight={4} flexDirection={"column"} >
            <Typography variant="h3" fontWeight={"600"} marginBottom={2} className='color-green'>Get in touch</Typography>
            <Typography style={{fontSize: "16", fontWeight: "500" }} className='color-green'>Chhutipur, Singur,</Typography>
            <Typography style={{fontSize: "16", fontWeight: "500" }} className='color-green'>Near: Sanyasi Ghata Mandir</Typography>
            <Typography style={{fontSize: "16", fontWeight: "500" }} className='color-green' marginBottom={2} >District: Hooghly.Pin code:712124. West Bengal.India.</Typography>
            <Typography style={{fontSize: "18", fontWeight: "600" }} marginTop={2} className='color-green'>Telephone:</Typography>
            <Typography variant="h5" marginTop={1} className='color-green' >+91 98308 13600</Typography>
            <Typography style={{fontSize: "16", fontWeight: "500"}} className='color-green' marginBottom={2}>+380 668001075</Typography>
            <Typography style={{fontSize: "18", fontWeight: "600" }} marginTop={1} className='color-green'>Email:</Typography>
            <Typography className='color-green' style={{ fontSize: "16", fontWeight: "600" }} marginTop={1}> <a href="mailto: marketing@carritoev.com">  marketing@carritoev.com </a>
            </Typography>
            <Grid display={"flex"} marginTop={3}>
              <Grid item marginRight={2}>
                <i className="fa fa-facebook" style={{ fontSize: 24, color: "white" }}></i>
              </Grid>
              <Grid item marginRight={2}>
                <i className="fa fa-twitter" style={{ fontSize: 24, color: "white" }}></i>
              </Grid>
              <Grid item  >
                <i className="fa fa-instagram" style={{ fontSize: 24, color: "white" }}></i>
              </Grid>
            </Grid>
          </Grid>
          <Grid lg={6} xl={6} md={5} sm={12} xs={12} marginTop={2}>
            <form onSubmit={handleFormSubmit}>
              <Grid container spacing={2}>
                {/* Second Row: Name, Email, Message */}
                <Grid item xs={12} sm={6} lg={12}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    style={{ backgroundColor: "white", fontSize: 16 }}
                    sx={{
                      '& .MuiFormLabel-root': {
                        fontSize: '16px',
                        color: "#62ff88"
                      },
                    }}

                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={12}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    style={{ backgroundColor: "white" }}
                    sx={{
                      '& .MuiFormLabel-root': {
                        fontSize: '16px',
                        color: "#62ff88"
                      },
                    }}

                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Message"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    style={{ backgroundColor: "white" }}
                    sx={{
                      '& .MuiFormLabel-root': {
                        fontSize: '16px',
                        color: "#62ff88"
                      },
                    }}

                  />
                </Grid>
              </Grid>

              <Button type="submit" variant="contained" style={{ marginTop: '16px', backgroundColor: "#62ff88", color: "#063559", fontWeight: "600", fontSize: 18 }}>
                Send
              </Button>
            </form>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ContactForm;
