
import React from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useLocation } from 'react-router-dom';
import Home from "./Home"
import Contact from "./Contact"
import JsonData from "../data/data.json";
import Carritoev from "../assets/Carritoev.png"

export default function Navigation() {
  return (
    <Router>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
        <div className='align-wide' style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", margin: 24, width: "100%" }}>
          <div>
            <NavLink to="/">
              <img src={Carritoev} style={{ height: 57, width: 175 }} />
            </NavLink>
          </div>
          <div>
            <NavLink to="/contact" style={{ color: "#62ff88", textDecoration: "underline", textUnderlineOffset: '0.25inch', fontWeight: 400, fontSize: 20 }}> CONTACT</NavLink>
          </div>
        </div>
      </div>
      <Routes>
        <Route path="/" element={<Home data={JsonData.Features} />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router >
  );
};


